import { styled } from "styled-components";

const LandingPageStyled = styled.div`
display: flex;
flex-direction: column;
align-items: center;

background: #ffffff;

`

export default LandingPageStyled;
