import { DefaultTheme } from "styled-components";

const theme: DefaultTheme = {
  fonts: {
    primary: "Oxanium",
    secondary: "29LT Adir",
    //primary: "mplus-1p",
    //secondary: "Istok Web",
    tertiary: "Oxanium",
  },
  colors: {
    mainLight: "#ffffff",
    base: "#0071bc",
    mainDark: "#00253d",
    mainNeutral: "#009245",
    mainNeutralLight: "#31a467",
    mainNeutralDark: "#005E2C",
  },
};

export default theme;
