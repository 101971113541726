import styled from "styled-components";

const SolutionsListStyled = styled.div`
  width: 100%;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 40px 60px 0px 60px;
`;

export default SolutionsListStyled;
